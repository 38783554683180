import { gql } from '@apollo/client';

const REGISTER_VOTER = gql`
  mutation registerVoter($email: String!, $name: String!, $extra: String) {
    registerVoter(name: $name, email: $email, extra: $extra) {
      id
      name
      email
      extra
    }
  }
`;

export default REGISTER_VOTER;
