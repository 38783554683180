import ReactGA from 'react-ga';

const initializeGoogleAnalytics = () => {
  ReactGA.initialize('G-EFFGNXG7KX');
};

const trackPageView = (title) => {
  ReactGA.pageview(title);
};

const trackCustomEvent = (event) => {
  ReactGA.event(event);
};

export { initializeGoogleAnalytics, trackPageView, trackCustomEvent };
