import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import util from 'util';
import useWelcome from '../hooks/useWelcome';
import useShow from '../hooks/useShow';
import LIST_EVENTS from '../graphql/queries/showList';
import showName from '../library/showName';
import getContrastYIQ from '../library/contrastHelper';
import i18n from '../i18n';
import SHOW_UPDATE from '../graphql/queries/showUpdate';

export const InitContext = React.createContext([{}, () => {}]);

export default (props) => {
  const { children } = props;
  const { setTermsAccepted } = useWelcome();
  const [hasShow, setHasShow] = useState(false);
  const {
    setModules,
    setEpisodeInfo,
    episodeInfo,
    setShowTheme,
    setLatestUpdate,
    latestUpdate,
    setLanguage,
    setShowInfo,
    setShowHeader,
    setShowMenu,
    showInfo,
    setWhitelabel,
  } = useShow();
  const {
    loading, error, data, refetch,
  } = useQuery(LIST_EVENTS, {
    variables: { domain_name: showName() },
  });
  const {
    loading: showUpdatesLoading, error: showUpdatesError, refetch: refetchUpdates,
  } = useQuery(SHOW_UPDATE, {
    variables: { domain_name: showName() },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    const intervalDuration = (showInfo && showInfo.poll_interval) ? showInfo.poll_interval : 10000;
    const interval = setInterval(async () => {
      const modalDom = document.querySelector('.ReactModal__Content');
      if (typeof modalDom === 'undefined' || modalDom === null) {
        const updatedData = await refetchUpdates();
        if (updatedData && updatedData.data.showByDomain) {
          const latestUpdateFromRoot = (moment(latestUpdate));
          const latestUpdateFromServer = (moment(updatedData.data.showByDomain.updated_at));
          if (latestUpdateFromRoot.isBefore(latestUpdateFromServer)) {
            await refetch();
            setHasShow(false);
          }
        }
      }
    }, intervalDuration);
    return () => clearInterval(interval);
  });
  useEffect(() => {
    if (episodeInfo && !episodeInfo.show_terms) {
      setTermsAccepted(true);
    }
    // eslint-disable-next-line
  }, [episodeInfo]);

  useEffect(() => {
    if (!hasShow && data && data.showByDomain) {
      setModules(data.showByDomain.episode.modules);
      setEpisodeInfo(data.showByDomain.episode);
      setShowHeader(data.showByDomain.display_header);
      setShowMenu(data.showByDomain.display_menu);
      setWhitelabel(data.showByDomain.whitelabel);
      const showThemeVars = {
        image: data.showByDomain.image,
        primary_color: data.showByDomain.primary_color,
        secondary_color: data.showByDomain.secondary_color,
        text_on_primary: getContrastYIQ(data.showByDomain.primary_color),
        text_on_secondary: getContrastYIQ(data.showByDomain.secondary_color),
      };
      setShowTheme(showThemeVars);
      if (data.showByDomain.display_info) {
        setShowInfo({
          display_info: true,
          name: data.showByDomain.name,
          description: data.showByDomain.description,
          poll_interval: data.showByDomain.poll_interval * 1000,
          company_name: data.showByDomain.company_name,
          voter_details: data.showByDomain.voter_details,
        });
      } else {
        setShowInfo({
          poll_interval: data.showByDomain.poll_interval * 1000,
          company_name: data.showByDomain.company_name,
          voter_details: data.showByDomain.voter_details,
        });
      }
      setLatestUpdate(data.showByDomain.updated_at);
      i18n.changeLanguage(data.showByDomain.language.toLowerCase());
      setLanguage(data.showByDomain.language.toLowerCase());
      // this is needed to prevent endless looping. Must be a better way, but for now it works
      setHasShow(true);
    }
    // eslint-disable-next-line
  }, [hasShow, data]);

  if (loading || showUpdatesLoading) {
    return (
      <Loader
        type="Grid"
        color="#00BFFF"
        height={100}
        width={100}
        style={{
          margin: 'auto auto',
        }}
      />
    );
  }
  if (error || showUpdatesError) {
    return <div>Error !</div>;
  }
  return (
    <InitContext.Provider>
      {children}
    </InitContext.Provider>
  );
};
